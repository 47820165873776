import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form } from "react-form";
import moment from "moment";
import { createSelector } from "../common/orm";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from "recharts";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import PollIcon from "@material-ui/icons/Poll";
import PowerIcon from "@material-ui/icons/Power";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MapIcon from "@material-ui/icons/Map";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Select from "../common/Select";
import TextField from "../common/TextField";
import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import WaterLevelDetailMap from "../common/map/WellSiteDetailMap";

import Icon from "@mdi/react";
import { mdiAlphaP, mdiAndroidStudio } from "@mdi/js";

import NotReady from "../common/NotReady";
import WellSiteDetails from "../../api/wellsiteDetailsHOC";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

import { saveAs } from "file-saver";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder3: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
    backgroundColor: "#eee",
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid " + theme.palette.primary.main,
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
  iconNotSelected: {
    color: "#666",
  },
  divideButton: {
    marginBottom: 16,
    width: "calc(50% - 16px)",
    marginTop: 16,
    minWidth: 44,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
  marginTop: {
    marginTop: 8,
    marginRight: 8,
  },
});

const columnData = [
  { id: "meas_date", numeric: false, label: "Date", allowSort: true },
  { id: "meas_time", numeric: false, label: "Time", allowSort: true },
  { id: "lab_name", numeric: false, label: "Lab Name", allowSort: true },
  {
    id: "analytical_method",
    numeric: true,
    label: "Analytical Method",
    allowSort: true,
  },
  {
    id: "characteristic",
    numeric: false,
    label: "Characteristic",
    allowSort: true,
  },
  { id: "result_value", numeric: true, label: "Result Value", allowSort: true },
  {
    id: "result_units",
    numeric: false,
    label: "Result Units",
    allowSort: true,
  },
  {
    id: "result_qualifier",
    numeric: false,
    label: "Result Qualifier",
    allowSort: true,
  },
  { id: "sampled_by", numeric: false, label: "Sampled By", allowSort: true },
  { id: "meas_comments", numeric: false, label: "Comments", allowSort: true },
];

const CustomizedDot = (props) => {
  const { cx, cy, r, stroke, payload, value, categoryColors } = props;
  const color = payload.qa_visible && !payload.submitted ? "#ff3d41" : "#FFFFFF";

  return (
     <circle cx={cx} cy={cy} r={r} stroke={stroke} strokeWidth={1} fill={color} />
  );
};

class WaterQualityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: "chart",
      order: "desc",
      orderBy: "meas_date",
      page: 0,
      rowsPerPage: 10,
      y_axis: "result_val",
      y_axis_label: "",
      start_date: null,
      end_date: null,
      min_value: null,
      max_value: null,
      result_units: "",
      measurements: [],
    };
    this.formKey = window.performance.now();
    this.printChart = this.printChart.bind(this);
  }

  componentDidUpdate() {
    const { start_date, y_axis, y_axis_label, measurements } = this.state;
    if (measurements.length > 0 && !start_date) {
      this.setState({
        start_date: this.getMinDate(measurements).getFullYear(),
        end_date: new Date().getFullYear(),
      });
      this.formKey = window.performance.now();
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.state.measurements.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  formatDate(dateString) {
    if (!dateString) {
      return null;
    }
    const d = new Date(dateString + " 12:00:00");
    return d.toLocaleDateString();
  }

  formatTime(timeString) {
    if (!timeString) {
      return null;
    }
    const d = new Date("2000-01-01 " + timeString);
    return d.toLocaleTimeString();
  }

  getMinDate(arr) {
    return new Date(
      arr.reduce(
        (min, p) =>
          new Date(p.meas_date + " 12:00:00") < min
            ? new Date(p.meas_date + " 12:00:00")
            : min,
        new Date(arr[0].meas_date + " 12:00:00")
      )
    );
  }

  getMaxDate(arr) {
    return new Date(
      arr.reduce(
        (max, p) =>
          new Date(p.meas_date + " 12:00:00") > max
            ? new Date(p.meas_date + " 12:00:00")
            : max,
        new Date(arr[0].meas_date + " 12:00:00")
      )
    );
  }

  printChart = () => {
    const { site } = this.state;
    var printWindow = window.open("", "PrintMap", "width=1200,height=850");
    printWindow.document.writeln(
      "DMS Site ID: " +
        site.dms_site_id +
        this.refs.chartContainer.container.innerHTML
    );
    printWindow.document.close();
  };

  handleAPIChange(data) {
    this.setState({ site: data.site, measurements: data.site.measurements });
  }

  exportData = (data, key) => {
    var huh = "Date,Units,Value,Characteristic\n";

    data.forEach((d) => {
      huh = `${huh}${d["meas_date"]},${
        d["result_units"] ? d["result_units"] : ""
      },${d["result_value"] ? d["result_value"] : ""},${
        d["characteristic"] ? d["characteristic"] : ""
      }\n`;
    });

    var filename = `${this.state.site.dms_site_id}_water_quality.csv`;
    var blob = new Blob([huh], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename);
  };

  render() {
    const { classes } = this.props;
    const {
      buttonState,
      page,
      rowsPerPage,
      order,
      orderBy,
      min_value,
      max_value,
      y_axis,
      y_axis_label,
      start_date,
      end_date,
      result_units,
      site,
      measurements,
    } = this.state;

    let siteId = parseInt(this.props.match.params["id"]);

    let level1 = "Water Quality Monitoring";
    let level1link = "/waterqualitylist";
    let level2link = "/waterqualitydetail/" + siteId;

    let characteristics = [];

    // Custom formatting of values for chart
    if (measurements.length > 0) {
      var filtered_measurements = measurements.slice();
      filtered_measurements.forEach(function (m) {
        if (!m.chart_date && m.meas_date) {
          var d;
          if (m.meas_time && m.meas_time !== "")
            d = new Date(m.meas_date + " " + m.meas_time);
          else d = new Date(m.meas_date + " 12:00:00");
          m.chart_date = d.getTime();
        }
        if (m.result_value) {
          m.result_value = parseFloat(m.result_value);
        }
      });

      //get the unique list of characteristics
      characteristics = [
        ...new Set(filtered_measurements.map((item) => item.characteristic)),
      ].map((u) => ({ label: u, value: u }));

      //add in the first result_units for each characteristic.
      characteristics.map((c) => {
        c.result_units = filtered_measurements.find(
          (e) => e.characteristic === c.label
        ).result_units;
      });

      //sort the array
      characteristics.sort((a, b) => (a.label > b.label ? 1 : -1));

      filtered_measurements.sort((m, n) => m.chart_date - n.chart_date);
      var minDate = this.getMinDate(filtered_measurements);
      var maxDate = this.getMaxDate(filtered_measurements);
      var yearoptions = [];
      var y = minDate.getFullYear();
      var z = new Date().getFullYear();
      while (y <= z) {
        yearoptions.push({ label: y, value: y });
        y++;
      }
      if (start_date) {
        filtered_measurements = filtered_measurements.filter(
          (m) => new Date(m.meas_date).getFullYear() >= parseInt(start_date)
        );
      }
      if (end_date) {
        filtered_measurements = filtered_measurements.filter(
          (m) => new Date(m.meas_date).getFullYear() <= parseInt(end_date)
        );
      }

      if (!result_units) {
        this.setState({
          result_units: filtered_measurements[0].result_units
            ? filtered_measurements[0].result_units
            : "NA",
        });
      }
    }

    if (site === undefined) {
      return (
        <AppContainer authenticated>
          <WellSiteDetails
            apitarget={"waterqualitymeasurements"}
            siteid={siteId}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1={level1}
                level1link={level1link}
                level2={"DMS ID: " + site.dms_site_id}
                level2link={level2link}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Monitoring Site Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <ContactMail style={{ marginTop: 5 }} />
                  <Typography style={{ paddingLeft: 32 }} variant="h6">
                    Identification/Location
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>DMS ID</b>: {site.dms_site_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Local Site ID</b>: {site.local_site_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Original Site ID</b>: {site.origin_site_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>State Well Number</b>: {site.dwr_well_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>CASGEM Well Number</b>: {site.casgem_well_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Source GSP Organization</b>: {site.source_org_label}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Owner</b>: {site.well_owner_label}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Monitored By</b>: {site.monitor_by_label}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Latitude</b>:{" "}
                        {site.geometry && site.geometry.coordinates[1]}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Longitude</b>:{" "}
                        {site.geometry && site.geometry.coordinates[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Coordinate Collection Method</b>:{" "}
                        {site.coordinate_method}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Coordinate Accuracy (ft)</b>:{" "}
                        {site.coordinate_accuracy}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Horizontal Datum</b>: {site.horz_datum}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>TRSQQ</b>:{" "}
                        {site.township +
                          site.range +
                          site.section +
                          site.quarter_section || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Use</b>: {site.well_use}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Status</b>: {site.well_status}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Completion Type</b>: {site.well_completion_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder2
                      )}
                      item
                      xs={6}
                      xl={4}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Icon
                    path={mdiAndroidStudio}
                    className={classes.marginTop}
                    size={1}
                  ></Icon>
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Ground Surface and Reference Point Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>RP and GS Elevation Measurement Date</b>:{" "}
                        {site.meas_elev_date}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Ground Surface Elevation</b>:{" "}
                        {site.ground_surface_elev}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Ground Surface Elevation Method</b>:{" "}
                        {site.elevation_method}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Ground Surface Elevation Accuracy</b>:{" "}
                        {site.elevation_accuracy}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Reference Point Elevation</b>: {site.rpe}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Reference Point Description</b>: {site.rp_desc}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Vertical Datum</b>: {site.vert_datum}
                      </Typography>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Icon
                    path={mdiAlphaP}
                    className={classes.marginLeft}
                    size={2}
                  ></Icon>
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Plan Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>GSP WL Threshold Elevation</b>:{" "}
                        {site.gsp_wl_threshold_elev}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Year 5 Interim Milestone (2025)</b>:{" "}
                        {site.yr5_milestone}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Year 10 Interim Milestone (2030)</b>:{" "}
                        {site.yr10_milestone}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Year 15 Interim Milestone (2035)</b>:{" "}
                        {site.yr15_milestone}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>GSP WL Measurable Objective</b>:{" "}
                        {site.measurable_objective}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>GSP Monitoring Network</b>:{" "}
                        {site.gsp_mon_network ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>SGMA Site Monitoring Type</b>:{" "}
                        {site.monitor_site_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Aquifier Designation</b>: {site.aquifer_designation}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Interconnected Monitoring</b>:{" "}
                        {site.interconnected_monitoring}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Water Quality Monitoring</b>: {site.wq_monitoring}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Water Level Monitoring</b>: {site.wl_monitoring}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Notes</b>: {site.well_notes}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder
                      )}
                      item
                      xs={6}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <LocalDrinkIcon style={{ marginTop: 10 }} />
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Drilling Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Total Well Depth (ft)</b>: {site.total_well_depth}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Perforated Intervals Top (ft)</b>:{" "}
                        {site.perforated_interval_top}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Drill Date</b>: {site.date_drilled}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Perforated Intervals Bottom (ft)</b>:{" "}
                        {site.perforated_interval_bottom}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Screened Intervals (ft)</b>:{" "}
                        {site.perforated_intervals_screen}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder2
                      )}
                      item
                      xs={6}
                      xl={4}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <PowerIcon style={{ marginTop: 10 }} />
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Motor/Pump Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Power Type</b>: {site.power_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Volumetric Pumping Calculation Type</b>:{" "}
                        {site.volumetric_calc_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder3
                      )}
                      item
                      xs={4}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                Measurement Data{" "}
                <Button
                  title="Table"
                  onClick={() =>
                    this.setState({ mapState: !this.state.mapState })
                  }
                >
                  <MapIcon
                    className={
                      this.state.mapState
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
                <Button
                  // fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => this.exportData(filtered_measurements, y_axis)}
                >
                  <Typography
                    style={{
                      textAlign: "left",
                      width: "100%",
                      color: "#fff",
                    }}
                  >
                    Export Tabular Well Data
                  </Typography>
                </Button>
              </Typography>{" "}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Select View</Typography>
            </Grid>
            <Grid item xs={8}>
              {site !== null && site.geometry && this.state.mapState && (
                <ResponsiveContainer
                  width="100%"
                  height={150}
                  ref="mapContainer"
                >
                  <WaterLevelDetailMap
                    geometry={site.geometry.coordinates}
                    height={"150px"}
                    width={"100%"}
                    zoom={7}
                  />
                </ResponsiveContainer>
              )}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.divideContainer}>
                <Button
                  title="Table"
                  onClick={() => this.setState({ buttonState: "table" })}
                  className={classNames(
                    classes.divideButton,
                    buttonState === "table"
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                >
                  <ListIcon
                    className={
                      buttonState === "table"
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
                <Button
                  title="Chart"
                  onClick={() => this.setState({ buttonState: "chart" })}
                  className={classNames(
                    classes.divideButton,
                    buttonState === "chart"
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                >
                  <PollIcon
                    className={
                      buttonState === "chart"
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
              </div>
            </Grid>
            {buttonState === "table" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={columnData}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {measurements
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              {this.formatDate(item.meas_date)}
                            </TableCell>
                            <TableCell>
                              {this.formatTime(item.meas_time)}
                            </TableCell>
                            <TableCell>{item.lab_name}</TableCell>
                            <TableCell>{item.analytical_method}</TableCell>
                            <TableCell>{item.characteristic}</TableCell>
                            <TableCell>{item.result_value}</TableCell>
                            <TableCell>{item.result_units}</TableCell>
                            <TableCell>{item.result_qualifier}</TableCell>
                            <TableCell>{item.sampled_by}</TableCell>
                            <TableCell>{item.meas_comments}</TableCell>
                          </TableRow>
                        ))}
                      {measurements.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Measurements Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {measurements.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={measurements.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            {buttonState === "chart" && (
              <>
                {measurements.length === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.centerAlign}>
                      No Measurements Found
                    </Typography>
                  </Grid>
                )}
                {measurements.length > 0 && (
                  <>
                    <Grid item xs={8}>
                      <ResponsiveContainer
                        width="100%"
                        height={680}
                        ref="chartContainer"
                      >
                        <LineChart
                          data={filtered_measurements.filter(function (c) {
                            let f =
                              y_axis_label !== ""
                                ? y_axis_label
                                : characteristics.length > 0
                                ? characteristics[0].label
                                : "";
                            return c.characteristic === f;
                          })}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="chart_date"
                            name="Measrement Date"
                            domain={["auto", "auto"]}
                            type="number"
                            tickFormatter={(unixTime) =>
                              moment(unixTime).format("MM/DD/YYYY")
                            }
                          />
                          <YAxis
                            // domain has to be in functions otherwise it doesnt update?
                            domain={[
                              (dataMin) =>
                                min_value ? parseFloat(min_value) : 0,
                              (dataMax) =>
                                max_value
                                  ? parseFloat(max_value)
                                  : Math.ceil(dataMax),
                            ]} // 'auto' doesnt work instead of math.ceil
                            dataKey="result_value"
                            type="number"
                          >
                            <Label
                              value={
                                y_axis_label &&
                                characteristics.find(
                                  (e) => e.value === y_axis_label
                                )
                                  ? characteristics.find(
                                      (e) => e.value === y_axis_label
                                    ).result_units
                                  : characteristics.length > 0
                                  ? characteristics[0].result_units
                                  : " "
                              }
                              position="center"
                              angle={-90}
                            />
                          </YAxis>
                          <Tooltip
                            labelFormatter={(value) =>
                              new Date(value).toLocaleDateString()
                            }
                          />
                          <Legend />
                          <Line
                            connectNulls
                            isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
                            name={
                              y_axis_label &&
                              characteristics.find(
                                (e) => e.value === y_axis_label
                              )
                                ? characteristics.find(
                                    (e) => e.value === y_axis_label
                                  ).value
                                : characteristics.length > 0
                                ? characteristics[0].value
                                : " "
                            }
                            type="monotone"
                            dataKey="result_value"
                            stroke="#0080be"
                            dot={<CustomizedDot />}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={4}>
                      <Form
                        key={this.formKey}
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        defaultValues={this.state}
                      >
                        {(formApi) => (
                          <form onSubmit={formApi.submitForm}>
                            <Grid
                              container
                              spacing={8}
                              className={classes.borderContainer2}
                            >
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Y Axis Values
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  field="y_axis"
                                  options={characteristics}
                                  fullWidth
                                  eventHandle={(val) => {
                                    this.setState({
                                      y_axis: val,
                                      y_axis_label: val,
                                    });
                                  }}
                                  value={
                                    y_axis_label &&
                                    characteristics.find(
                                      (e) => e.value === y_axis_label
                                    )
                                      ? characteristics.find(
                                          (e) => e.value === y_axis_label
                                        ).value
                                      : characteristics.length > 0
                                      ? characteristics[0].value
                                      : " "
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Summary Statistics
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  <b>Date Range</b>:&nbsp;
                                  {minDate.toLocaleDateString()}&nbsp;-&nbsp;
                                  {maxDate.toLocaleDateString()}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Filter Monitor Data
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  field="start_date"
                                  label="Start Date"
                                  eventHandle={(val) =>
                                    this.setState({ start_date: val })
                                  }
                                  options={yearoptions}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  field="end_date"
                                  label="End Date"
                                  eventHandle={(val) =>
                                    this.setState({ end_date: val })
                                  }
                                  options={yearoptions}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Chart Settings
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  field="min_value"
                                  type="number"
                                  label="Y Axis Min Value"
                                  eventHandle={(val) =>
                                    this.setState({ min_value: val })
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  field="max_value"
                                  type="number"
                                  label="Y Axis Max Value"
                                  eventHandle={(val) =>
                                    this.setState({ max_value: val })
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  onClick={this.printChart}
                                >
                                  <Typography
                                    style={{
                                      textAlign: "left",
                                      width: "100%",
                                      color: "#fff",
                                    }}
                                  >
                                    Print Chart
                                  </Typography>
                                  <ChevronRight style={{ float: "right" }} />
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Form>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </AppContainer>
      );
    }
  }
}
WaterQualityDetail = connect((state, ownProps) => ({}))(WaterQualityDetail);

export default withStyles(styles)(WaterQualityDetail);
